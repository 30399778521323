const common = {
    log_in_help: 'Log in help',
    contact_us: 'Contact us for help',
    privacy_policy: 'Privacy Policy',
    terms_of_service: 'Terms of Service',
    email_text_field_label: 'Enter your email',
    password_text_field_label: 'Password',
    new_password_text_field_label: 'Enter New Password',
    confirm_password_text_field_label: 'Confirm New Password',
    first_name_required_error: 'First name is required',
    last_name_required_error: 'Last name is required',
    company_name_required_error: 'Company name is required',
    country_required_error: 'Select your country',
    email_required_error: 'Email is required',
    email_invalid_error: 'Enter a valid email',
    password_required_error: 'Password is required',
    must_match_the_new_password: 'Must match the new password',
    confirm_password_is_required: 'Please confirm the password',
    phone_number_required_error: 'Phone number is required',
    password_complexity_message: 'Password must contain at least: 12 characters, one Upper and lower case letter, and one number'
};

const loginPage = {
    login_page_header: 'Log in to your SoftActivity Work account',
    select_an_organization: 'Select an Organization to Login to',
    login_error_401_message: 'The email and/or password is invalid. Please check and try again. Forgot your password?',
    login_error_403_message: 'Sorry, we could not log you in.',
    login_error_no_organizations: 'This user does not have access to organizations',
    login_error_message: 'Sorry, we could not log you in because of an error. Please try again, contact your IT administrator or SoftActivity support.',
    tenants_error_message: 'Sorry, we could not log you in. Please try again later or contact SoftActivity support',
    recover_here: 'Recover here.',
    log_in: 'Log In',
    log_out: 'Log Out',
    details: 'Details:',
    troubles_logging_in: 'Forgot your password?',
    dont_have_account: 'Don\'t have an account?',
    sign_up_now: 'Sign up your organization now',
    or_ask_admin: 'or ask your admin to invite you.',
    login_with_microsoft: 'Log In with Microsoft',
    login_with_google: 'Login with Google',
    continue_with_microsoft: 'Continue with your Microsoft Account',
    continue_with_google: 'Continue with your Google Account',
    invite_error: 'Unable to accept the invite. Please try again, request a new invite, or contact SoftActivity support.',
    microsoft_access_denied: 'Access to your Microsoft account was denied. Please try again and allow access or contact your IT administrator.',
    login_failed_microsoft: 'Sorry, we could not log you in with this Microsoft account. Please login with a correct account, or contact your IT administrator for an invite.',
}

const signUpPage = {
    sign_up_page_header: 'Sign up for SoftActivity Work account',
    sign_up: 'Sign up',
    return_to_log_in: 'Return to log in',
}

const invitationPage = {
    invitation_page_header: 'Invitation to join SoftActivity Work',
    invitation_page_text_block1: `You've been invited to join`,
    invitation_page_text_block2: `organization at SoftActivity Work, productivity software for organizations. To accept and sign up for an account, please select how you want to login.`,
    invitation_page_text_block3: `If this invitation was sent to you by mistake and you do not wish to join, please do not accept it. No further action is required.`,
    accept_invite: 'Accept Invite',
    continue_with_email: 'Continue with Email',
    ask_to_resend_invitation: 'Please ask the sender to resend your invitation.',
    accept_the_terms: 'In order to continue, please accept the terms'
}

const completePasswordResetPage = {
    password_recovery_page_header: 'Reset Your Password',
    password_recovery_page_sub_header: "You requested a password reset. Please enter your new password below.",
    change_password: 'Reset Password',
    error_recovery_code_message: 'This password recovery link is either invalid or expired. If you need, request a new password reset',
    here: 'here',
    password_reset_success: "Password Reset Completed",
    password_reset_success_message: "Your password has been reset successfully.",
    click_here_to_log_in: "Click here to log in",
  };

const RequestPasswordResetPage = {
    password_reset_page_header: 'Reset Password',
    password_reset_page_sub_header: 'Enter the email address you use to login into your SoftActivity Work account.',
    check_email_for_recovery_link: 'Check your email for a password recovery link, if you have an account with this email address.',
    send_recovery_link: 'Send Recovery Link',
}

const notFoundPage = {
    go_to: 'Go to',
    page_not_found: 'Page Not Found',
}

const translations = {
    ...common,
    ...loginPage,
    ...signUpPage,
    ...invitationPage,
    ...completePasswordResetPage,
    ...RequestPasswordResetPage,
    ...notFoundPage
}
export default translations;
