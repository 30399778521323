import { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useFormik, FormikProvider } from 'formik';
import * as yup from 'yup';
import {
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutlined';
import authService from '../api/auth_service';
import { tP, translatePipe } from '../i18n';
import { requiredPasswordField } from '../utils/validationField';
import HeaderLogo from '../components/HeaderLogo';
import '../App.scss';

const validationSchema = yup.object().shape({
  new_password: requiredPasswordField,
  confirm_password: yup
    .string()
    .oneOf([yup.ref('new_password')], tP('must_match_the_new_password'))
    .required(tP('confirm_password_is_required'))
});

function CompletePasswordReset() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [isShowNewPassword, setIsShowNewPassword] = useState<boolean>(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState<boolean>(false);
  const [pendingRecoveryPassword, setPendingRecoveryPassword] = useState<boolean>(false);

  const [errorDataDetail, setErrorDataDetail] = useState<string | null>(null);
  const [recoveryCodeVerified, setRecoveryCodeVerified] = useState<boolean>(false);
  const [resetSuccess, setResetSuccess] = useState<boolean>(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const recovery_code = searchParams.get('recovery_code');

  // Sign out + verify code
  useEffect(() => {
    if (!recovery_code) {
      setIsLoading(false);
      return;
    }

    authService.signOut().finally(() => {
      authService
        .verifyRecoveryCode({ recovery_code })
        .then(() => {
          setRecoveryCodeVerified(true);
        })
        .catch((err: any) => {
          setErrorDataDetail(err?.response?.data?.detail);
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  }, [recovery_code]);

  const formik = useFormik({
    initialValues: {
      new_password: '',
      confirm_password: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (recovery_code && !pendingRecoveryPassword) {
        const payload = { recovery_code, password: values.new_password };
        setPendingRecoveryPassword(true);
        setErrorDataDetail(null);
        authService
          .recoveryPasswordCreate(payload)
          .then(() => {
            setPendingRecoveryPassword(false);
            setResetSuccess(true);
          })
          .catch((err) => {
            setPendingRecoveryPassword(false);
            setErrorDataDetail(err?.response?.data?.detail);
          });
      }
    }
  });

  // 1) If still loading => show spinner
  if (isLoading) {
    return (
      <div className="page-wrapper flex f-d-col a-i-c">
        <HeaderLogo />
        <div className="body-wrapper flex a-i-c">
          <CircularProgress size={45} thickness={5} style={{ color: '#88cbf0' }} />
        </div>
        <div className="footer flex j-s-e w-100" style={{ paddingBottom: '30px' }}>
          <a
            className="link-to"
            href="https://www.softactivity.com/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {translatePipe('privacy_policy')}
          </a>
          <a
            className="link-to"
            href="https://www.softactivity.com/terms-of-service/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {translatePipe('terms_of_service')}
          </a>
        </div>
      </div>
    );
  }

  // 2) If no code or code not verified => show error
  if (!recovery_code || !recoveryCodeVerified) {
    return (
      <div className="page-wrapper flex f-d-col a-i-c">
        <HeaderLogo />
        <div className="body-wrapper flex a-i-c">
          <div className="form-wrapper flex f-d-col j-c a-i-c">
            <div className="title-form">
              {translatePipe('password_recovery_page_header')}
            </div>
            <div className="flex a-i-c error-message mt-20">
              <ErrorOutlineOutlinedIcon />
              <div className="ml-10">
                {translatePipe('error_recovery_code_message')}{' '}
                <Link className="link-to" to="/reset-password">
                  {translatePipe('here')}
                </Link>
                {errorDataDetail && (
                  <div className="mt-10">
                    {translatePipe('details')}&nbsp;{errorDataDetail}
                  </div>
                )}
              </div>
            </div>
              <Link className="link-to mt-20" to="/log-in">
                {translatePipe('return_to_log_in')}
              </Link>
          </div>
        </div>
        <div className="footer flex j-s-e w-100" style={{ paddingBottom: '30px' }}>
          <a
            className="link-to"
            href="https://www.softactivity.com/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {translatePipe('privacy_policy')}
          </a>
          <a
            className="link-to"
            href="https://www.softactivity.com/terms-of-service/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {translatePipe('terms_of_service')}
          </a>
        </div>
      </div>
    );
  }

  // 3) If resetSuccess => show success message (within the form-wrapper)
  if (resetSuccess) {
    return (
      <div className="page-wrapper flex f-d-col a-i-c">
        <HeaderLogo />
        <div className="body-wrapper flex a-i-c">
          <div className="form-wrapper flex f-d-col j-c a-i-c">
            <div className="title-form">{translatePipe('password_reset_success')}</div>
            <div className="flex a-i-c success-message">
              <CheckCircleOutlineIcon />
              <div>
                {translatePipe('password_reset_success_message')}
                <Link className="link-to mt-20" to="/log-in">
                  {translatePipe('log_in')}
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="footer flex j-s-e w-100" style={{ paddingBottom: '30px' }}>
          <a
            className="link-to"
            href="https://www.softactivity.com/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {translatePipe('privacy_policy')}
          </a>
          <a
            className="link-to"
            href="https://www.softactivity.com/terms-of-service/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {translatePipe('terms_of_service')}
          </a>
        </div>
      </div>
    );
  }

  // 4) Otherwise, show the password form
  return (
    <FormikProvider value={formik}>
      <div className="page-wrapper flex f-d-col a-i-c">
        <HeaderLogo />
        <div className="body-wrapper flex a-i-c">
          <div className="form-wrapper flex f-d-col j-c a-i-c">
            <div className="title-form">
              {translatePipe('password_recovery_page_header')}
            </div>
            {errorDataDetail && (
              <div className="flex a-i-c error-message mt-20">
                <ErrorOutlineOutlinedIcon />
                <div className="ml-10">{errorDataDetail}</div>
              </div>
            )}
            <div className="text-form">
              {translatePipe('password_recovery_page_sub_header')}
            </div>
            <form
              className="flex f-d-col w-100 mt-20"
              autoComplete="off"
              onSubmit={formik.handleSubmit}
            >
              <FormControl
                variant="outlined"
                size="small"
                error={
                  formik.touched.new_password &&
                  Boolean(formik.errors.new_password)
                }
              >
                <InputLabel htmlFor="standard-adornment-password">
                  {translatePipe('new_password_text_field_label')}
                </InputLabel>
                <OutlinedInput
                  id="new_password"
                  name="new_password"
                  label={translatePipe('new_password_text_field_label')}
                  type={isShowNewPassword ? 'text' : 'password'}
                  value={formik.values.new_password}
                  onChange={formik.handleChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setIsShowNewPassword(!isShowNewPassword)}
                      >
                        {isShowNewPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText id="component-helper-text">
                  {(formik.touched.new_password && formik.errors.new_password) ||
                    ' '}
                </FormHelperText>
              </FormControl>

              <FormControl
                variant="outlined"
                size="small"
                error={
                  formik.touched.confirm_password &&
                  Boolean(formik.errors.confirm_password)
                }
              >
                <InputLabel htmlFor="standard-adornment-password">
                  {translatePipe('confirm_password_text_field_label')}
                </InputLabel>
                <OutlinedInput
                  id="confirm_password"
                  name="confirm_password"
                  label={translatePipe('confirm_password_text_field_label')}
                  type={isShowConfirmPassword ? 'text' : 'password'}
                  value={formik.values.confirm_password}
                  onChange={formik.handleChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setIsShowConfirmPassword(!isShowConfirmPassword)
                        }
                      >
                        {isShowConfirmPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText id="component-helper-text">
                  {(formik.touched.confirm_password && formik.errors.confirm_password) ||
                    ' '}
                </FormHelperText>
              </FormControl>
              <Button variant="contained" className="button w-100" type="submit">
                {translatePipe('change_password')}
                {pendingRecoveryPassword && (
                  <CircularProgress
                    size={15}
                    thickness={5}
                    style={{ color: 'white' }}
                  />
                )}
              </Button>
              <Link className="link-to mt-20" to="/log-in">
                {translatePipe('return_to_log_in')}
              </Link>
            </form>
          </div>
        </div>
        <div className="footer flex j-s-e w-100" style={{ paddingBottom: '30px' }}>
          <a
            className="link-to"
            href="https://www.softactivity.com/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {translatePipe('privacy_policy')}
          </a>
          <a
            className="link-to"
            href="https://www.softactivity.com/terms-of-service/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {translatePipe('terms_of_service')}
          </a>
        </div>
      </div>
    </FormikProvider>
  );
}

export default CompletePasswordReset;
