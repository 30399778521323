import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useFormik, FormikProvider } from 'formik';
import * as yup from 'yup';
import 'yup-phone';
import {
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
  Checkbox,
  Divider, 
  Typography

} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import authService from '../api/auth_service';
import { tP, translatePipe } from '../i18n';
import { requiredPasswordField } from '../utils/validationField';
import HeaderLogo from '../components/HeaderLogo';
import { config } from '../config/index'; // for AUTH_URL
import MicrosoftLogo from '../assets/microsoft-logo.svg';
import GoogleLogo from '../assets/google-logo.svg';
import '../App.scss';

// Validation for new + confirm password fields if user must set password
const validationSchema = yup.object().shape({
  new_password: requiredPasswordField,
  confirm_password: yup
    .string()
    .oneOf([yup.ref('new_password')], tP('must_match_the_new_password'))
    .required(tP('confirm_password_is_required'))
});

function InvitationPage() {

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isShowNewPassword, setIsShowNewPassword] = useState<boolean>(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState<boolean>(false);

  // Whether the user is actively sending Accept Invite request
  const [pendingAccept, setPendingAccept] = useState<boolean>(false);

  // CheckBox for user agreement
  const [isAgreedRules, setIsAgreedRules] = useState<boolean>(false);
  const [showTermError, setShowTermError] = useState<boolean>(false);

  // Data returned from verifying the invite code
  const [inviteData, setInviteData] = useState<{
    tenant: string;
    set_password: boolean;
  } | null>(null);

  // If an error occurs (e.g., invalid invite code), store the message
  const [errorDataDetail, setErrorDataDetail] = useState<string | null>(null);

  // Extract invite_code from the URL query params
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const invite_code = searchParams.get('invite_code');

  // If user checks the box => hide error
  useEffect(() => {
    if (isAgreedRules) {
      setShowTermError(false);
    }
  }, [isAgreedRules]);

  // 1) On page load: sign out any existing user, then verify invite code
  useEffect(() => {
    if (!invite_code) {
      // No invite_code => No sign out or verification needed
      setIsLoading(false);
      return;
    }

    // Force logout
    authService.signOut().finally(() => {
      // Then verify the invite code
      authService
        .verifyInviteCode({ invite_code })
        .then((resp) => {
          setInviteData(resp.data);
        })
        .catch((err: any) => {
          setErrorDataDetail(err?.response?.data?.detail);
        })
        .finally(() => {
          // done signOut + verifying
          setIsLoading(false);
        });
    });
  }, [invite_code]);

  // Only validate password if the user must set one
  const formik = useFormik({
    initialValues: {
      new_password: '',
      confirm_password: ''
    },
    validationSchema: inviteData?.set_password ? validationSchema : null,
    onSubmit: (values) => {
      if (!isAgreedRules) {
        setShowTermError(true);
        return;
      }
      if (invite_code && !pendingAccept) {
        let payload;
        if (inviteData?.set_password) {
          // user must set a password
          payload = { invite_code, password: values.new_password };
        } else {
          // user does not need to set a password
          payload = { invite_code };
        }
        setPendingAccept(true);
        authService
          .acceptInviteCode(payload)
          .then((resp) => {
            setPendingAccept(false);
            if (resp?.data?.tenant_url) {
              // redirect to the tenant URL
              window.location.href = resp?.data?.tenant_url;
            }
          })
          .catch(() => setPendingAccept(false));
      }
    }
  });

  // Helper: If error encountered, show an error block
  const renderErrorStatus = useMemo(() => {
    if (errorDataDetail) {
      return (
        <div className="flex a-i-c error-wrapper">
          <ErrorOutlineOutlinedIcon />
          <div>
            {errorDataDetail}
            <div>{translatePipe('ask_to_resend_invitation')}</div>
            <Link className="link-to mt-20" to="/log-in">
              {translatePipe('return_to_log_in')}
            </Link>
          </div>
        </div>
      );
    }
    return null;
  }, [errorDataDetail]);

  // 2) If isLoading => show spinner
  if (isLoading) {
    return (
      <div className="page-wrapper flex f-d-col a-i-c">
        <HeaderLogo />
        <div className="body-wrapper flex a-i-c">
          <CircularProgress size={45} thickness={5} style={{ color: '#88cbf0' }} />
        </div>
      </div>
    );
  }

  // 3) If we have an error => show it
  if (errorDataDetail) {
    return (
      <div className="page-wrapper flex f-d-col a-i-c">
        <HeaderLogo />
        <div className="body-wrapper flex a-i-c">
          <div className="form-wrapper flex f-d-col j-c a-i-c">
            {renderErrorStatus}
          </div>
        </div>
        <div className="footer flex j-s-e w-100" style={{ paddingBottom: '30px' }}>
          <a
            className="link-to"
            href="https://www.softactivity.com/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {translatePipe('privacy_policy')}
          </a>
          <a
            className="link-to"
            href="https://www.softactivity.com/terms-of-service/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {translatePipe('terms_of_service')}
          </a>
        </div>
      </div>
    );
  }

  // 4) If no invite code or no data => Possibly show nothing or an empty page
  if (!invite_code || !inviteData) {
    return (
      <div className="page-wrapper flex f-d-col a-i-c">
        <HeaderLogo />
        <div className="body-wrapper flex a-i-c">
          <div className="form-wrapper flex f-d-col j-c a-i-c">
            <div className="flex a-i-c error-message mt-20">
            <ErrorOutlineOutlinedIcon />
            <div className="ml-10">
            No invite code. 
            <Link className="link-to mt-20" to="/log-in">
              {translatePipe('return_to_log_in')}
            </Link>
            </div>
            </div>
          </div>
        </div>
        <div className="footer flex j-s-e w-100" style={{ paddingBottom: '30px' }}>
          <a
            className="link-to"
            href="https://www.softactivity.com/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {translatePipe('privacy_policy')}
          </a>
          <a
            className="link-to"
            href="https://www.softactivity.com/terms-of-service/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {translatePipe('terms_of_service')}
          </a>
        </div>
      </div>
    );
  }

  // 5) Otherwise, show the actual invite form
  return (
    <FormikProvider value={formik}>
      <div className="page-wrapper flex f-d-col a-i-c">
        <HeaderLogo />
        <div className="body-wrapper flex a-i-c">
          <div className="form-wrapper flex f-d-col j-c a-i-c">
            <div className="title-form">
              {translatePipe('invitation_page_header')}
            </div>
            <div className="text-form">
              {translatePipe('invitation_page_text_block1')} <b>{inviteData.tenant}</b>{' '}
              {translatePipe('invitation_page_text_block2')}
            </div>
            <form
              className="flex f-d-col w-100 mt-20"
              autoComplete="off"
              onSubmit={formik.handleSubmit}
            >
              {inviteData.set_password && (
                <>
                  <FormControl
                    variant="outlined"
                    size="small"
                    error={
                      formik.touched.new_password &&
                      Boolean(formik.errors.new_password)
                    }
                  >
                    <InputLabel htmlFor="new_password">
                      {translatePipe('new_password_text_field_label')}
                    </InputLabel>
                    <OutlinedInput
                      id="new_password"
                      name="new_password"
                      label={translatePipe('new_password_text_field_label')}
                      type={isShowNewPassword ? 'text' : 'password'}
                      value={formik.values.new_password}
                      onChange={formik.handleChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              setIsShowNewPassword(!isShowNewPassword)
                            }
                          >
                            {isShowNewPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    <FormHelperText id="component-helper-text">
                      {(formik.touched.new_password && formik.errors.new_password) ||
                        ' '}
                    </FormHelperText>
                  </FormControl>

                  <FormControl
                    variant="outlined"
                    size="small"
                    error={
                      formik.touched.confirm_password &&
                      Boolean(formik.errors.confirm_password)
                    }
                  >
                    <InputLabel htmlFor="confirm_password">
                      {translatePipe('confirm_password_text_field_label')}
                    </InputLabel>
                    <OutlinedInput
                      id="confirm_password"
                      name="confirm_password"
                      label={translatePipe('confirm_password_text_field_label')}
                      type={isShowConfirmPassword ? 'text' : 'password'}
                      value={formik.values.confirm_password}
                      onChange={formik.handleChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              setIsShowConfirmPassword(!isShowConfirmPassword)
                            }
                          >
                            {isShowConfirmPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    <FormHelperText id="component-helper-text">
                      {(formik.touched.confirm_password && formik.errors.confirm_password) ||
                        ' '}
                    </FormHelperText>
                  </FormControl>
                </>
              )}

              <div className="flex a-i-c mt-20">
                <Checkbox
                  checked={isAgreedRules}
                  onChange={() => setIsAgreedRules(!isAgreedRules)}
                  className="checkbox-form"
                />
                <div className="ml-10">
                  I agree to SoftActivity{' '}
                  <a
                    className="link-to"
                    href="https://www.softactivity.com/privacy-policy/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {translatePipe('privacy_policy')}
                  </a>{' '}
                  and{' '}
                  <a
                    className="link-to"
                    href="https://www.softactivity.com/terms-of-service/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {translatePipe('terms_of_service')}
                  </a>
                </div>
              </div>
              {showTermError && (
                <div className="ml-10 mt-10 error-term">
                  {translatePipe('accept_the_terms')}
                </div>
              )}

              <div className="mt-20 flex f-d-col">
                <Button
                  variant="contained"
                  className="button w-100"
                  type="submit"
                  style={{ marginBottom: '10px' }}
                >
                  {inviteData.set_password ? translatePipe('continue_with_email') : translatePipe('accept_invite')}
    
                  {pendingAccept && (
                    <CircularProgress
                      size={15}
                      thickness={5}
                      style={{ color: 'white' }}
                    />
                  )}
                </Button>

                {/* {inviteData.set_password && (
                  <>
                    <div className="divider-or">
                      <Divider className="divider-line" />
                      <Typography variant="body2" className="divider-text">OR</Typography>
                      <Divider className="divider-line" />
                    </div>

                    <Button
                      variant="contained"
                      component="a"
                      className="button social-button"
                      startIcon={<img src={MicrosoftLogo} alt="Microsoft" className="icon" />}
                      onClick={() => {
                        if (!isAgreedRules) {
                          setShowTermError(true);
                          return;
                        }
                        // If user has agreed, redirect to MS invite accept
                        window.location.href = `${config.AUTH_URL}/microsoft/invite/?invite_code=${invite_code}`;
                      }}
                    >
                      {translatePipe('continue_with_microsoft')}
                    </Button>
                    <Button
                      variant="contained"
                      component="a"
                      className="button social-button"
                      startIcon={<img src={GoogleLogo} alt="Microsoft" className="icon" />}
                      onClick={() => {
                        if (!isAgreedRules) {
                          setShowTermError(true);
                          return;
                        }
                        // If user has agreed, redirect to MS invite accept
                        window.location.href = `${config.AUTH_URL}/google/invite/?invite_code=${invite_code}`;
                      }}
                    >
                      {translatePipe('continue_with_google')}
                    </Button>
                  </>
                )} */}
              </div>
            </form>

            <div className="text-form mt-20">
              {translatePipe('invitation_page_text_block3')}
            </div>
          </div>
        </div>

        <div className="footer flex j-s-e w-100" style={{ paddingBottom: '30px' }}>
          <a
            className="link-to"
            href="https://www.softactivity.com/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {translatePipe('privacy_policy')}
          </a>
          <a
            className="link-to"
            href="https://www.softactivity.com/terms-of-service/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {translatePipe('terms_of_service')}
          </a>
        </div>
      </div>
    </FormikProvider>
  );
}

export default InvitationPage;
